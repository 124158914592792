<template>
  <v-app>
    <v-navigation-drawer
      app
      left
      stateless
      v-model="drawer"
      :mini-variant="mini"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="user.details && user.details.logo"></v-img>
        </v-list-item-avatar>
        <v-list-item-title class="font-weight-bold">{{
          user.name
        }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense link>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="item in menu"
            :key="item.title"
            link
            :to="{ name: item.title }"
            exact
            class="text-decoration-none"
          >
            <v-list-item-icon>
              <v-icon>mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="deep-purple accent-4" dense dark>
      <v-app-bar-nav-icon
        @click.stop="menuOpen = !menuOpen"
        class="d-none d-md-block"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->

      <v-menu offset-y close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="notificationCount"
            :value="notificationCount"
            color="red"
            overlap
            v-if="show"
            class="mr-3"
          >
            <v-icon size="26" v-bind="attrs" v-on="on" @click="markAsRead">
              mdi-bell
            </v-icon>
          </v-badge>
          <v-badge
            :content="notificationCount"
            :value="0"
            color="red"
            overlap
            v-else
            class="mr-3"
          >
            <v-icon size="26" v-bind="attrs" v-on="on"> mdi-bell </v-icon>
          </v-badge>
        </template>

        <v-list class="p-0" dense>
          <v-list-item v-for="item in notifications" :key="item.orderCode">
            <v-list-item-content>
              <v-list-item-title>
                <h5>
                  <strong>{{ item.user }}</strong> has placed a new order for:
                  <strong
                    v-for="orderedProducts in item.items"
                    :key="orderedProducts.id"
                  >
                    <li>{{ orderedProducts.product.name }}</li>
                  </strong>
                </h5>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center see-all-notification">
                <router-link to="/notifications">See All</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="pl-3" style="cursor: pointer !important">
            <!-- <v-avatar size="24px" color="#ddd">
              <v-icon>mdi-account</v-icon>
            </v-avatar> -->
            <span class="px-2">
              Vendor
              <v-icon>mdi-chevron-down</v-icon>
            </span>
          </div>
        </template>

        <v-list class="p-0">
          <v-list-item link to="/account">
            <v-list-item-title>
              <v-icon class="pr-4" small>mdi-key</v-icon>Account
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logout">
              <v-icon class="pr-4" small>mdi-logout</v-icon>Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app>
      <p class="mb-0 text-center" style="width: 100%">
        <v-icon size="24" class="pr-2">mdi-copyright</v-icon
        >{{ todayDate }} Saajha | All Rights Reserved
      </p>
    </v-footer>
  </v-app>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      selectedItem: 0,
      show: true,
      messages: 1,
      menuOpen: false,
      menu: [
        {
          icon: "speedometer",
          title: "Dashboard",
        },
        {
          icon: "store",
          title: "Products",
        },
        {
          icon: "chart-bar-stacked",
          title: "Inventory",
        },
        {
          icon: "gift",
          title: "Orders",
        },

         {
          icon: "cart-arrow-down",
          title: "Custom Orders",
        },
        // {
        //   icon: "wallet",
        //   title: "Wallet",
        // },
        // {
        //   icon: "wallet-plus",
        //   title: "Withdraw",
        // },
        {
          icon: "message-draw",
          title: "Reviews",
        },
        // {
        //   icon: "help-box",
        //   title: "Support",
        // },
        // {
        //   icon: "flash",
        //   title: "FlashDeal",
        // },
        {
          icon: "bell",
          title: "Notifications",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      notifications: (state) => state.notifications,
    }),
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mini() {
      return this.mdAndDown || this.menuOpen;
    },
    todayDate() {
      return new Date().getFullYear();
    },
    notificationCount() {
      return this.notifications.length;
    },
  },
  methods: {
    ...mapActions([
      "getVendorProducts",
      "getCategories",
      "getVendorReviews",
      "getUser",
      "getBrands",
      "getTags",
      // "getWallet",
      "getOrders",
      "getUnreadNotification",
      "getCustomOrders"

      // "getWithdrawRequest",
      // "getBankDetails",
    ]),

    logout() {
      localStorage.removeItem("v_token");
      this.$router.push("/");
    },
    markAsRead() {
      this.show = false;
      this.$store.dispatch("markAsRead");
    },
  },
  created() {
    if (!this.$store.state.products.length) {
      this.getUser();
      this.getVendorProducts();
      this.$store.dispatch("getUnreadNotification");
      this.getCategories();
      this.getBrands();
      this.getTags();
      this.getVendorReviews();
      // this.getWallet();
      this.getOrders();
      this.getCustomOrders();
      // this.getWithdrawRequest();
      // this.getBankDetails();
    }
  },
};
</script>
<style lang="scss">
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.see-all-notification a {
  text-decoration: none;
}
.primary-color {
  color: $primary-color;
}
.v-list-item-group .v-list-item--active {
  color: rgb(255, 34, 65) !important;
}
</style>
